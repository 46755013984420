import React from "react";
import { useState } from "react";
import { useCreateFormMutation } from "../../redux/asianFormApiSlice";
import PowerliftingAsianGameForm from "./PowerliftingAsianGameForm";
import { useNavigate } from "react-router-dom";

const FormSubmissionScreen = () => {
  const [createForm, { isLoading, isSuccess, isError, error }] = useCreateFormMutation();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const navigate = useNavigate();

  const initialData = {
    name: "", // Name of the participant
    email: "", // Participant's email
    country: "", // Participant's country
    contactNumber: "", // Contact number of the participant
    emergencyContactName: "", // Name of the emergency contact
    emergencyContactNumber: "", // Emergency contact's phone number
    passportNumber: "", // Participant's passport number
    dob: "", // Date of Birth in string format (e.g., "YYYY-MM-DD")
    category: "", // Either "Open" or "Age group"
    gender: "", // Gender of the participant (e.g., "Male" or "Female")
    weightCategory: "", // Weight category (e.g., "70-80kg")
  };

  const handleSubmit = async (formData) => {
    try {
      const plainObject = {}; // To convert FormData back to plain object for debugging/logging

      // Convert FormData entries to plain object
      formData.forEach((value, key) => {
        plainObject[key] = value;
      });

      const response = await createForm({
        formDTO: plainObject, // Pass the plain object
        profileImage: formData.get("profileImage"), // Pass the file separately
      }).unwrap();

      // Extract the payment URL from the response and redirect
      if (response.paymentUrl) {
        window.location.href = response.paymentUrl; // Redirect user to the payment URL
      } else {
        throw new Error("Payment URL not found in the response.");
      }

      setFormSubmitted(true);
    } catch (err) {
      console.error("Form submission failed:", err);
      setSubmissionError(err.message || "An error occurred while submitting the form.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-3xl">
        {formSubmitted && isSuccess ? (
          <div className="bg-green-100 text-green-800 p-4 rounded mb-6">
            <p>Form submitted successfully! Redirecting to payment...</p>
          </div>
        ) : null}

        {isError || submissionError ? (
          <div className="bg-red-100 text-red-800 p-4 rounded mb-6">
            <p>Error submitting form: {error?.data?.message || submissionError || "Unknown error."}</p>
          </div>
        ) : null}

        <PowerliftingAsianGameForm initialData={initialData} onSubmit={handleSubmit} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default FormSubmissionScreen;
