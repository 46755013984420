import { apiSlice } from "./apiSlice";

export const asianGameFormApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Fetch all forms
    getForms: builder.query({
      query: () => ({
        url: `/api/public/forms`,
        method: "GET",
      }),
      providesTags: ["Forms"],
    }),

    // Fetch a form by ID
    getFormById: builder.query({
      query: (id) => ({
        url: `/api/public/forms/${id}`,
        method: "GET",
      }),
      providesTags: ["Forms"],
    }),

    // Create a new form and generate a PayPal payment URL
    createForm: builder.mutation({
      query: ({ formDTO, profileImage }) => {
        const formData = new FormData();
    
        // Loop through formDTO and append to formData
        Object.keys(formDTO).forEach((key) => {
          // Handle array or object data by serializing them to JSON strings
          const value = formDTO[key];
          formData.append(key, Array.isArray(value) || typeof value === "object" ? JSON.stringify(value) : value);
        });
    
        // Append the profile image if present
        if (profileImage) {
          formData.append("profileImage", profileImage);
        }
    
    
        return {
          url: `/api/public/forms`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Forms"],
    }),
    

    // Capture PayPal payment
    capturePayment: builder.mutation({
      query: ({ orderId, formId }) => ({
        url: `/api/public/forms/capture-payment?orderId=${orderId}&formId=${formId}`, // Sending as query params
        method: "POST",
      }),
      invalidatesTags: ["Forms"],
    }),
    
  }),
  overrideExisting: true,
});

export const {
  useGetFormsQuery,
  useGetFormByIdQuery,
  useCreateFormMutation,
  useCapturePaymentMutation,
} = asianGameFormApiSlice;
