// AddMoneyFlow.js
import MoneyFlowForm from "./MoneyFlowForm";
import useSnackbar from "../../hooks/useSnackbar";
import { useCreateMoneyFlowMutation } from "../../redux/moneyFlowApiSlice";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";

const AddMoneyFlow = () => {
  const [createMoneyFlow, { isLoading }] = useCreateMoneyFlowMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { gymId } = useSelector(selectGym);


  const initialData = {
    billNo: "",
    transactionDate: "",
    transactionType: "",
    tags: [],
    amount: 0,
    description: "",
    gymId: gymId,
  };

  const handleSubmit = async (moneyFlowData) => {
    try {
      await createMoneyFlow(moneyFlowData).unwrap();
      showSnackbar("Money Flow added successfully!", "success");
    } catch (err) {
      console.error("Failed to add money flow:", err);
      showSnackbar("Failed to add money flow.", "error");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Add Money Flow</h1>
      <MoneyFlowForm initialData={initialData} onSubmit={handleSubmit} isLoading={isLoading} />
      <SnackbarComponent />
    </div>
  );
};

export default AddMoneyFlow;