import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";
import { useGetAggregatedReportQuery, useGetUniqueTagsByGymIdQuery } from "../../redux/moneyFlowApiSlice";
import TagDistributionScreen from "./TagDistributionScreen";

// Register ChartJS modules
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const AggregatedReportScreen = () => {
  const { gymId } = useSelector(selectGym);

  // State for filters
  const [transactionType, setTransactionType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportType, setReportType] = useState("MONTHLY");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [showGraph, setShowGraph] = useState(true);

  // Query data
  const { data: aggregatedReport, isLoading, error } = useGetAggregatedReportQuery({
    gymId,
    transactionType,
    startDate: startDate ? `${startDate}T00:00:00` : null,
    endDate: endDate ? `${endDate}T23:59:59` : null,
    tags: selectedTags,
    reportType,
  });

  const { data: tagOptions } = useGetUniqueTagsByGymIdQuery(gymId);

  // Handle specific month and year selection
  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    if (month) {
      setStartDate(`${selectedYear}-${month}-01`);
      setEndDate(`${selectedYear}-${month}-31`);
      setReportType("DAILY");
    }
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    if (selectedMonth) {
      setStartDate(`${year}-${selectedMonth}-01`);
      setEndDate(`${year}-${selectedMonth}-31`);
      setReportType("DAILY");
    }
  };

  // Sort aggregated report data by date
  const sortedReport = aggregatedReport?.slice().sort((a, b) => new Date(a.period) - new Date(b.period));

  // Prepare chart data
  const chartData = {
    labels: sortedReport?.map((item) => item.period) || [],
    datasets: [
      {
        label: "Income",
        data: sortedReport?.map((item) => item.totalIncome) || [],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Expense",
        data: sortedReport?.map((item) => item.totalExpense) || [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: `${reportType} Aggregated Report` },
    },
  };

  return (
    <div className="p-6">
      {/* Filters */}
      <div className="p-4 mb-6 bg-gray-100 rounded">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Transaction Type */}
          <div>
            <label className="block mb-2 font-medium">Transaction Type</label>
            <select
              value={transactionType}
              onChange={(e) => setTransactionType(e.target.value)}
              className="w-full border rounded p-2"
            >
              <option value="">All</option>
              <option value="INCOME">Income</option>
              <option value="EXPENSE">Expense</option>
            </select>
          </div>

          {/* Start Date */}
          <div>
            <label className="block mb-2 font-medium">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full border rounded p-2"
            />
          </div>

          {/* End Date */}
          <div>
            <label className="block mb-2 font-medium">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full border rounded p-2"
            />
          </div>

          {/* Report Type */}
          <div>
            <label className="block mb-2 font-medium">Report Type</label>
            <select
              value={reportType}
              onChange={(e) => setReportType(e.target.value)}
              className="w-full border rounded p-2"
            >
              <option value="MONTHLY">Monthly</option>
              <option value="DAILY">Daily</option>
            </select>
          </div>

          {/* Specific Month and Year */}
          <div className="flex gap-2 items-end">
            <div>
              <label className="block mb-2 font-medium">Specific Month</label>
              <select
                value={selectedMonth}
                onChange={(e) => handleMonthChange(e.target.value)}
                className="w-full border rounded p-2"
              >
                <option value="">Select Month</option>
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={String(month).padStart(2, "0")}>
                    {new Date(0, month - 1).toLocaleString("default", { month: "long" })}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block mb-2 font-medium">Year</label>
              <select
                value={selectedYear}
                onChange={(e) => handleYearChange(e.target.value)}
                className="w-full border rounded p-2"
              >
                {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          {/* Tags */}
          <div className="col-span-full">
            <label className="block mb-2 font-medium">Tags</label>
            <div className="flex flex-wrap gap-2">
              {tagOptions?.map((tag) => (
                <div key={tag} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id={`tag-${tag}`}
                    value={tag}
                    checked={selectedTags.includes(tag)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedTags([...selectedTags, tag]);
                      } else {
                        setSelectedTags(selectedTags.filter((t) => t !== tag));
                      }
                    }}
                  />
                  <label htmlFor={`tag-${tag}`} className="mr-4">
                    {tag}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Toggle Button */}
      <button
        onClick={() => setShowGraph(!showGraph)}
        className="px-6 py-2 mb-4 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        {showGraph ? "Show Table" : "Show Graph"}
      </button>

      {/* Chart or Table */}
      <div className="mt-8 flex justify-center">
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>Error loading data</p>
        ) : showGraph ? (
          <div className="w-full max-w-3xl" style={{ height: "400px" }}>
            <Bar data={chartData} options={chartOptions} />
          </div>
        ) : (
          <table className="mt-8 w-2/3 border border-collapse">
            <thead>
              <tr>
                <th className="border p-2">Month</th>
                <th className="border p-2">Total Income</th>
                <th className="border p-2">Total Expense</th>
                <th className="border p-2">Net Income</th>
              </tr>
            </thead>
            <tbody>
              {sortedReport?.map((item) => (
                <tr key={item.period}>
                  <td className="border p-2">{item.period}</td>
                  <td className="border p-2">{item.totalIncome}</td>
                  <td className="border p-2">{item.totalExpense}</td>
                  <td className="border p-2">
                    {item.totalIncome - item.totalExpense}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <TagDistributionScreen />
    </div>
  );
};

export default AggregatedReportScreen;
