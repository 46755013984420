import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

import ourStoryImage from "../../assets/sukdev.jpg";
import divasImage from "../../assets/divas.jpg";
import sukadevImage from "../../assets/sukadev.png";
import teamImage from "../../assets/team.jpg";

const About = () => {
  return (
    <div className="bg-gray-50 py-20">
      <div className="container max-w-7xl mx-auto px-6 lg:px-8">
        {/* About Header */}
        <div className="text-center mb-20">
          <h2 className="text-5xl font-extrabold text-blue-700">About Us</h2>
          <p className="text-lg text-gray-600 mt-4">
            Empowering the Fitness Community, One Connection at a Time.
          </p>
        </div>

        {/* Who We Are Section */}
        <section className="mb-20">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row">
            <div className="md:w-1/2">
              <img
                src={ourStoryImage}
                alt="Our Story"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="md:w-1/2 p-10 flex flex-col justify-center">
              <h3 className="text-3xl font-bold text-blue-700 mb-4">Who We Are</h3>
              <p className="text-lg text-gray-700 leading-relaxed">
                At Byayam.com, we are a dedicated community committed to bridging
                the gap between gyms, trainers, and fitness enthusiasts. Our
                mission is to provide a unified platform where gyms can efficiently
                manage their operations, trainers can connect with clients, and
                individuals can access personalized workout and diet plans.
              </p>
            </div>
          </div>
        </section>

        {/* Our Mission Section */}
        <section className="mb-20">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col md:flex-row-reverse">
            <div className="md:w-1/2">
              <img
                src={teamImage}
                alt="Our Mission"
                className="object-cover w-full h-full"
              />
            </div>
            <div className="md:w-1/2 p-10 flex flex-col justify-center">
              <h3 className="text-3xl font-bold text-blue-700 mb-4">Our Mission</h3>
              <p className="text-lg text-gray-700 leading-relaxed">
                Our mission is to create a comprehensive network that seamlessly
                integrates gyms, trainers, and clients. We aim to revolutionize
                the fitness industry by offering advanced CMS tools for gyms,
                providing trainers with a platform to connect with clients, and
                delivering customized workout and diet plans to clients.
              </p>
            </div>
          </div>
        </section>

        {/* Our Team Section */}
        <section className="mb-20 text-center">
          <h3 className="text-4xl font-bold text-blue-700 mb-12">Our Team</h3>
          <div className="flex flex-wrap justify-center gap-12">
            <div className="w-full md:w-1/3 lg:w-1/4">
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                <img
                  src={sukadevImage}
                  alt="Team Member"
                  className="w-32 h-32 mx-auto rounded-full mb-4 shadow-lg"
                />
                <h4 className="text-xl font-bold text-gray-800">Sukdev Karki</h4>
                <p className="text-gray-600 mb-4">CEO & Founder</p>
                <p className="text-gray-600">
                  A visionary in community building and fitness, Sukdev leads
                  Byayam.com with a passion for connecting people.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 lg:w-1/4">
              <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                <img
                  src={divasImage}
                  alt="Team Member"
                  className="w-32 h-32 mx-auto rounded-full mb-4 shadow-lg"
                />
                <h4 className="text-xl font-bold text-gray-800">Dibas Regmi</h4>
                <p className="text-gray-600 mb-4">CTO & Founder</p>
                <p className="text-gray-600">
                  With a strong background in technology, Dibas drives innovation at
                  Byayam.com, ensuring our platform meets the needs of all users.
                </p>
              </div>
            </div>
            {/* Add more team members if needed */}
          </div>
        </section>

        {/* Social Media Links */}
        <section className="text-center mt-20">
          <h3 className="text-4xl font-bold text-blue-700 mb-8">Follow Us</h3>
          <div className="flex justify-center gap-8">
            <a
              href="https://www.facebook.com"
              className="text-blue-600 hover:text-white hover:bg-blue-600 transition-colors duration-300 p-3 rounded-full shadow-md"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF size={24} />
            </a>
            <a
              href="https://www.twitter.com"
              className="text-blue-600 hover:text-white hover:bg-blue-600 transition-colors duration-300 p-3 rounded-full shadow-md"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://www.instagram.com"
              className="text-blue-600 hover:text-white hover:bg-blue-600 transition-colors duration-300 p-3 rounded-full shadow-md"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.linkedin.com"
              className="text-blue-600 hover:text-white hover:bg-blue-600 transition-colors duration-300 p-3 rounded-full shadow-md"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn size={24} />
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
