
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ExpiredUsersList from "./ExpiredUsersList";
import TopInfoSection from "./TopInfoSection";
import UserTypesPieChart from "./UserTypesPieChart";
import MonthlyPaymentsBarChart from "./MonthlyPaymentsBarChart";
import FinancialSummarySection from "../money/FinancialSummarySection";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ProtectedHome = () => {
  return (
    <div className="min-h-screen bg-gray-100 px-4 py-6">
      <div className="container mx-auto">
        <TopInfoSection />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="md:col-span-1 grid grid-cols-1 gap-6">
            <FinancialSummarySection />
            <UserTypesPieChart />
            <MonthlyPaymentsBarChart />
          </div>
          <div className="md:col-span-1 md:row-span-2">
            <ExpiredUsersList />
          </div>
        </div>
      </div>
    </div>
  );
};



export default ProtectedHome;
