import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";
import { useGetTagDistributionQuery } from "../../redux/moneyFlowApiSlice";

// Register ChartJS modules
ChartJS.register(Title, Tooltip, Legend, ArcElement);

const TagDistributionScreen = () => {
  const { gymId } = useSelector(selectGym);

  // State for date range filters
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Prepare query parameters dynamically
  const queryParams = { gymId };
  if (startDate) queryParams.startDate = `${startDate}T00:00:00`;
  if (endDate) queryParams.endDate = `${endDate}T23:59:59`;

  // Query to fetch tag distribution
  const { data: distributionData, isLoading, error } = useGetTagDistributionQuery(queryParams);

  // Prepare data for Income Pie Chart
  const incomeData = {
    labels: distributionData?.incomeDistribution
      ? Object.keys(distributionData.incomeDistribution)
      : [],
    datasets: [
      {
        data: distributionData?.incomeDistribution
          ? Object.values(distributionData.incomeDistribution)
          : [],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 205, 86, 0.6)",
          "rgba(201, 203, 207, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 205, 86, 1)",
          "rgba(201, 203, 207, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Prepare data for Expense Pie Chart
  const expenseData = {
    labels: distributionData?.expenseDistribution
      ? Object.keys(distributionData.expenseDistribution)
      : [],
    datasets: [
      {
        data: distributionData?.expenseDistribution
          ? Object.values(distributionData.expenseDistribution)
          : [],
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="p-6">
      <div className="p-4 mb-6 bg-gray-100 rounded">
        <h2 className="text-xl font-medium mb-4">Tag Distribution</h2>

        {/* Date Filters */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Start Date */}
          <div>
            <label className="block mb-2 font-medium">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full border rounded p-2"
            />
          </div>

          {/* End Date */}
          <div>
            <label className="block mb-2 font-medium">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full border rounded p-2"
            />
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {/* Income Distribution */}
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-medium mb-4">Income Distribution</h3>
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error loading income data</p>
          ) : (
            <div style={{ width: "200px", height: "200px" }}>
              <Pie data={incomeData} />
            </div>
          )}
        </div>

        {/* Expense Distribution */}
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-medium mb-4">Expense Distribution</h3>
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error loading expense data</p>
          ) : (
            <div style={{ width: "200px", height: "200px" }}>
              <Pie data={expenseData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TagDistributionScreen;
