import React from "react";
import { Route } from "react-router-dom";
import Layout from "../Layout";
import ErrorPage from "../ScreensPublic/ErrorPage";
import PublicHome from "../ScreensPublic/Home/PublicHome";
import About from "../ScreensPublic/about/About";
import GymCms from "../ScreensPublic/gym/GymCms";
import Contact from "../ScreensPublic/contact/contact";
import StoreLogin from "../ScreensPublic/auth/StoreLogin";
import SignUp from "../ScreensPublic/auth/SignUp";
import RouteConfig from "../config/RouteConfig";
import PublicRoute from "./PublicRoute";
import JudgeResultSetter from "../ScreensPublic/nyfc/JudgeResultSetter";
import FormSubmissionScreen from "../ScreensPublic/asianGame/FormSubmissionScreen";
import FormCompletion from "../ScreensPublic/asianGame/FormCompletion";

const PublicRouteCollection = () => {
  return (
    <Route element={<PublicRoute />}>
      <Route path={"/asian-form"} element={<FormSubmissionScreen />} />
      <Route path={"/payment-success"} element={<FormCompletion />} />

      <Route
        element={<Layout isProtected={false} />}
        errorElement={<ErrorPage />}
      >
        <Route index path={"/"} element={<PublicHome />} />
        <Route path={RouteConfig.ABOUT} element={<About />} />
        <Route path={RouteConfig.GYM_CMS} element={<GymCms />} />

        {/* <Route path={RouteConfig.PRICING} element={<Pricing />} /> */}
        <Route path={RouteConfig.CONTACT} element={<Contact />} />
        <Route path={RouteConfig.NYFC_GAME} element={<JudgeResultSetter />} />
        <Route path={RouteConfig.LOGIN} element={<StoreLogin />} />
        <Route path={RouteConfig.SIGNUP} element={<SignUp />} />
      </Route>
    </Route>
  );
};

export default PublicRouteCollection;
