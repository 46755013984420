import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useAddPaymentMutation } from "../../redux/clientPaymentApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import ClientPaymentForm from "./ClientPaymentForm";
import useSnackbar from "../../hooks/useSnackbar";
import { useGetClientByIdQuery } from "../../redux/clientApiSlice";

const ClientAddPayments = () => {
  const navigate = useNavigate();

  const { clientId, invoiceId } = useParams();
  const {
    data: client,
    isLoading,
    isError,
    error,
  } = useGetClientByIdQuery(clientId);
  const [addPayment, { isLoading: isAdding }] = useAddPaymentMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const initialData = {
    clientId: clientId,
    invoiceId: invoiceId, // Set this to the appropriate invoice ID or leave it empty if optional
    amount: "",
    paymentDate: dayjs().format("YYYY-MM-DD"),
    paymentType: "",
    billNo: "", 
  };

  const handleSubmit = async (paymentData) => {
    try {
      await addPayment(paymentData).unwrap();
      navigateOneStepBack();
    } catch (err) {
      console.error("Failed to add payment:", err);
      showSnackbar(
        "Failed to add payment: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  const navigateOneStepBack = () => {
    const currentPath = window.location.pathname;
    const newPath = currentPath.split("/").slice(0, -1).join("/");
    navigate(newPath);
  };

  return (
    <FetchWrapper isLoading={isLoading} isError={isError} error={error}>
      {client && (
        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold mb-4">
            Add Payment for {client.name}
          </h1>
          <ClientPaymentForm
            initialData={initialData}
            onSubmit={handleSubmit}
            isLoading={isAdding}
          />
          <SnackbarComponent />
        </div>
      )}
    </FetchWrapper>
  );
};

export default ClientAddPayments;
