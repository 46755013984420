import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom"; // For URL parameter extraction
import { useCapturePaymentMutation } from "../../redux/asianFormApiSlice"; // Adjust to your API slice
import FORM_IMG from "../../assets/asian_powerlifting_form.jpg"; // Import the image

const FormCompletion = () => {
  const location = useLocation(); // Hook to get the current location
  const [capturePayment, { isLoading, isSuccess, isError, error }] =
    useCapturePaymentMutation();

  const [message, setMessage] = useState("");
  const [userName, setUserName] = useState(""); // Store user's name from backend
  const hasCalledApi = useRef(false); // Ref to track API call

  // Extract formId and token from the URL
  const searchParams = new URLSearchParams(location.search);
  const formId = searchParams.get("formId");
  const token = searchParams.get("token");

  useEffect(() => {
    if (formId && token && !hasCalledApi.current) {
      // Call the capturePayment API only if it hasn't been called yet
      hasCalledApi.current = true; // Mark API call as made
      capturePayment({ orderId: token, formId })
        .unwrap()
        .then((response) => {
          console.log(response);
          
          if (response.success) {
            setUserName(response.name); // Set the name from the response
            setMessage(`Thank you, ${response.name}, for registering for the Asian Powerlifting Championship!`);
          } else {
            setMessage("Payment capture failed: " + response.message);
          }
        })
        .catch((err) => {
          setMessage("An error occurred during the payment process.");
        });
    }
  }, [formId, token, capturePayment]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      {/* Container with shadow and padding */}
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
        {/* Image */}
        <img src={FORM_IMG} alt="Asian Powerlifting Championship" className="mb-6  h-56 object-cover " />
        
        {/* Message Container */}
        <div className="text-center">
          {isLoading ? (
            <div className="text-xl text-gray-700 font-semibold">Processing payment...</div>
          ) : (
            <div className="text-xl text-green-600 font-semibold">{message}</div>
          )}

          {isError && !isLoading && (
            <div className="mt-4 text-red-600">
              {error?.data?.message || "An error occurred during the payment process."}
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="mt-8 text-sm text-gray-500">
          <p>For any inquiries, please contact us.</p>
          <p>Email: mail.nyfc.nepal@gmail.com</p>
          <p>WhatsApp: +977 9823403130</p>
        </div>
      </div>
    </div>
  );
};

export default FormCompletion;
