import { FaArrowUp, FaArrowDown, FaBalanceScale } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";
import { useGetMoneyFlowBasicInfoQuery } from "../../redux/moneyFlowApiSlice";
import FetchWrapper from "../../components/FetchWrapper";

const FinancialSummarySection = () => {
  const { gymId } = useSelector(selectGym);

  const { data, error, isLoading } = useGetMoneyFlowBasicInfoQuery(gymId);

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-6">Financial Summary</h1>

        {/* Top Info Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Row 1: Total Income and Total Expense */}
          <div className="col-span-1 bg-gradient-to-br from-green-500 to-green-300 text-white p-6 rounded-xl shadow-xl flex items-center">
            <FaArrowUp size={30} className="mr-4" />
            <div>
              <h2 className="text-lg font-semibold mb-2">Total Income</h2>
              <p className="text-2xl font-bold">{`रु ${data?.totalIncome}`}</p>
            </div>
          </div>
          <div className="col-span-1 bg-gradient-to-br from-red-500 to-red-300 text-white p-6 rounded-xl shadow-xl flex items-center">
            <FaArrowDown size={30} className="mr-4" />
            <div>
              <h2 className="text-lg font-semibold mb-2">Total Expense</h2>
              <p className="text-2xl font-bold">{`रु ${data?.totalExpense}`}</p>
            </div>
          </div>

          {/* Row 2: Net Income */}
          <div className="col-span-1 md:col-span-2 bg-gradient-to-br from-blue-500 to-blue-300 text-white p-6 rounded-xl shadow-xl flex items-center ">
            <FaBalanceScale size={30} className="mr-4" />
            <div>
              <h2 className="text-lg font-semibold mb-2">Net Income</h2>
              <p className="text-2xl font-bold">{`रु ${data?.netIncome}`}</p>
            </div>
          </div>
        </div>

        {/* Income and Expense By Tag Table */}
        <div className="bg-white shadow-xl rounded-lg p-6">
          <h2 className="text-lg font-semibold mb-4">Income and Expense By Tag</h2>
          <div className="overflow-x-auto">
            <table className="table-auto w-full text-left border-collapse">
              <thead className="bg-gray-200 text-gray-700">
                <tr>
                  <th className="px-4 py-2">Tag</th>
                  <th className="px-4 py-2 text-green-600">Income</th>
                  <th className="px-4 py-2 text-red-600">Expense</th>
                </tr>
              </thead>
              <tbody>
                {/* Merge tags from both incomeByTag and expenseByTag */}
                {Object.keys({
                  ...data?.incomeByTag,
                  ...data?.expenseByTag,
                }).map((tag) => (
                  <tr key={tag}>
                    <td className="px-4 py-2 border-t">{tag}</td>
                    <td className="px-4 py-2 border-t text-green-600">
                      {data?.incomeByTag?.[tag] || 0}
                    </td>
                    <td className="px-4 py-2 border-t text-red-600">
                      {data?.expenseByTag?.[tag] || 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </FetchWrapper>
  );
};

export default FinancialSummarySection;
