import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useCreateInvoiceMutation } from "../../../redux/clientInvoiceApiSlice";
import useSnackbar from "../../../hooks/useSnackbar";
import FetchWrapper from "../../../components/FetchWrapper";
import InvoiceForm from "./InvoiceForm";

const AddInvoice = () => {
  const navigate = useNavigate();

  const { clientId } = useParams();
  const [createInvoice, { isLoading }] = useCreateInvoiceMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const initialData = {
    clientId: clientId,
    pricingPlanDto: null,
    membershipStartDate: dayjs().format("YYYY-MM-DD"),
    membershipEndDate: "",
    totalAmount: 0,
    discountedAmount: 0,
    amountPaid: 0,
    amountDue: 0,
  };

  const handleSubmit = async (invoiceData) => {
    try {
      const response = await createInvoice(invoiceData).unwrap();

      // Extract the invoiceId from the response
      const invoiceId = response.id; // Ensure this matches the key in the API response

      // Navigate to the add-payment page using the extracted invoiceId
      navigate(`/clients/${clientId}/invoices/${invoiceId}/add-payment`);
    } catch (err) {
      console.error("Failed to create invoice:", err);
      showSnackbar(
        "Failed to create invoice: " + (err.data?.message || "Unknown error"),
        "error"
      );
    }
  };

  return (
    <FetchWrapper isLoading={isLoading}>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">Add Invoice</h1>
        <InvoiceForm
          initialData={initialData}
          onSubmit={handleSubmit}
          isLoading={isLoading}
        />
        <SnackbarComponent />
      </div>
    </FetchWrapper>
  );
};

export default AddInvoice;
