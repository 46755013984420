import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config/config.json";
import { logout } from "./userSlice";
import { showAlert } from "./alertSlice";
import { isTokenExpired } from "../utils/authUtils";

// Utility to check if the app is running in Electron
const isElectron = () => {
  return !!(window && window.process && window.process.type);
};

const baseQueryWithTokenCheck = async (args, api, extraOptions) => {
  const { getState, dispatch } = api;
  const token = getState().user.token;

  // Log the request details
  console.log("Frontend Hit backendAPI:", args);

  if (token && isTokenExpired(token)) {
    console.log(isTokenExpired(token));
    console.log("expired msg");

    dispatch(
      showAlert({
        message: "Session has expired. Please log in again.",
        action: logout(),
      })
    );
  }

  // Set base URL based on whether the app is running in Electron or Web
  const baseUrl = isElectron() ? config.BASE_URL_ELECTRON : config.BASE_URL_WEB;

  const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  return baseQuery(args, api, extraOptions);
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithTokenCheck,
  tagTypes: [
    "Categories",
    "SMS",
    "SMSCount",
    "Attendance",
    "GymsSMSSetting",
    "AccessControlDevices",
    "Users",
    "Enums",
    "Payments",
    "Invoices",
    "Clients",
    "DashboardMetrics",
    "MonthlyPayments",
    "Gyms",
  ],
  endpoints: (builder) => ({}),
});
