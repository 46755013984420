import { FaUsers, FaChartLine, FaDollarSign } from "react-icons/fa";
import { useGetDashboardCardDataQuery } from "../../redux/dashboardApiSlice";
import FetchWrapper from "../../components/FetchWrapper";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";

const TopInfoSection = () => {
  const { gymId } = useSelector(selectGym);

  const { data, error, isLoading } = useGetDashboardCardDataQuery(gymId);
  // bg-red-300 bg-opacity-60

  return (
    <FetchWrapper isLoading={isLoading} isError={!!error} error={error}>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 my-4">
        {/* User Info Cards */}
        <div className="col-span-1 bg-gradient-to-br from-blue-500 to-blue-300 bg-opacity-60 text-white p-6 rounded-xl shadow-xl flex items-center">
          <FaUsers size={30} className="mr-4" />
          <div>
            <h2 className="text-lg font-semibold mb-2">Total Users</h2>
            <p className="text-2xl font-bold">{data?.totalUsers}</p>{" "}
            {/* Dynamically display total users */}
          </div>
        </div>
        <div className="col-span-1 bg-gradient-to-br from-purple-500 to-purple-400 bg-opacity-60  text-white p-6 rounded-xl shadow-xl flex items-center">
          <FaChartLine size={30} className="mr-4" />
          <div>
            <h2 className="text-lg font-semibold mb-2">Total Active Members</h2>
            <p className="text-2xl font-bold">
              {data?.totalActiveMembers}
            </p>{" "}
            {/* Dynamically display total active members */}
          </div>
        </div>

        {/* Revenue Info Cards */}
        <div className="col-span-1 bg-gradient-to-br from-teal-500 to-teal-300 text-white p-6 rounded-xl shadow-xl flex items-center">
          <FaDollarSign size={30} className="mr-4" />
          <div>
            <h2 className="text-lg font-semibold mb-2">Monthly Membership</h2>
            <p className="text-2xl font-bold">{`रु ${data?.totalRevenueThisMonth}`}</p>{" "}
          </div>
        </div>
        <div className="col-span-1 bg-gradient-to-br from-green-500 to-green-300 text-white p-6 rounded-xl shadow-xl flex items-center">
          <FaDollarSign size={30} className="mr-4" />
          <div>
            <h2 className="text-lg font-semibold mb-2">
              Total Membership Collection{" "}
            </h2>
            <p className="text-2xl font-bold">{`रु ${data?.totalRevenueToDate}`}</p>{" "}
            {/* Dynamically display total revenue to date */}
          </div>
        </div>
      </div>
    </FetchWrapper>
  );
};

export default TopInfoSection;
