// EditMoneyFlow.js
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetMoneyFlowByIdQuery, useUpdateMoneyFlowMutation } from "../../redux/moneyFlowApiSlice";
import useSnackbar from "../../hooks/useSnackbar";
import MoneyFlowForm from "./MoneyFlowForm";


const EditMoneyFlow = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: moneyFlowData, isLoading: isFetching } = useGetMoneyFlowByIdQuery(id);
  const [updateMoneyFlow, { isLoading }] = useUpdateMoneyFlowMutation();
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (moneyFlowData) {
      setInitialData(moneyFlowData);
    }
  }, [moneyFlowData]);

  const handleSubmit = async (updatedMoneyFlowData) => {

    
    try {
      await updateMoneyFlow({ id, ...updatedMoneyFlowData }).unwrap();
      showSnackbar("Money Flow updated successfully!", "success");
      navigate(-1); // Navigate back to the previous screen
    } catch (err) {
      console.error("Failed to update money flow:", err);
      showSnackbar("Failed to update money flow.", "error");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Edit Money Flow</h1>
      {initialData && (
        <MoneyFlowForm initialData={initialData} onSubmit={handleSubmit} isLoading={isFetching || isLoading} />
      )}
      <SnackbarComponent />
    </div>
  );
};

export default EditMoneyFlow;
