import React, { useState } from "react";
import FormCardWrapper from "../../components/FormCardWrapper";
import FORM_IMG from "../../assets/asian_powerlifting_form.jpg";

const PowerliftingAsianGameForm = ({
  initialData = {},
  onSubmit,
  isLoading,
}) => {
  const [formData, setFormData] = useState({
    ...initialData,
    category: initialData.category ? initialData.category.split(",") : [], // Default as an array
  });
  const [profileImage, setProfileImage] = useState(null);
  const [errors, setErrors] = useState({});

  const weightCategories = {
    Men: [
      { value: "62kg", label: "62kg Class up to 62.00 kg" },
      { value: "69kg", label: "69kg Class from 62.05kg up to 69.00 kg" },
      { value: "77kg", label: "77kg Class from 69.05kg up to 77.00 kg" },
      { value: "85kg", label: "85kg Class from 77.05kg up to 85.00 kg" },
      { value: "94kg", label: "94kg Class from 85.05kg up to 94.00 kg" },
      { value: "105kg", label: "105kg Class from 94.05kg up to 105.00 kg" },
      { value: "120kg", label: "120kg Class from 105.05kg up to 120.00 kg" },
      { value: "120+kg", label: "120+kg Class 120.05kg and over" },
    ],
    Women: [
      { value: "48kg", label: "48kg Class up to 48.00 kg" },
      { value: "53kg", label: "53kg Class from 48.05kg up to 53.00 kg" },
      { value: "58kg", label: "58kg Class from 53.05kg up to 58.00 kg" },
      { value: "64kg", label: "64kg Class from 58.05kg up to 64.00 kg" },
      { value: "72kg", label: "72kg Class from 64.05kg up to 72.00 kg" },
      { value: "84kg", label: "84kg Class from 72.05kg up to 84.00 kg" },
      { value: "100kg", label: "100kg Class from 84.05kg up to 100.00kg" },
      { value: "100+kg", label: "100+kg Class 100.05kg and over" },
    ],
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio" && name === "gender") {
      // Reset weight category when gender changes
      setFormData((prev) => ({
        ...prev,
        gender: value,
        weightCategory: "", // Reset weight category
      }));
    }

    if (type === "checkbox") {
      const updatedCategories = [...formData.category];

      if (checked) {
        updatedCategories.push(value);
      } else {
        const index = updatedCategories.indexOf(value);
        if (index > -1) updatedCategories.splice(index, 1);
      }

      setFormData((prev) => ({
        ...prev,
        category: updatedCategories,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && !file.type.startsWith("image/")) {
      setErrors((prev) => ({
        ...prev,
        profileImage: "Please upload a valid image file.",
      }));
      setProfileImage(null);
    } else {
      setErrors((prev) => ({ ...prev, profileImage: null }));
      setProfileImage(file);
    }
  };

  const validate = () => {
    let tempErrors = {};
    const requiredFields = [
      "name",
      "email",
      "country",
      "contactNumber",
      "emergencyContactName",
      "emergencyContactNumber",
      "passportNumber",
      "dob",
      "category",
      "gender",
      "weightCategory",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) tempErrors[field] = `${field} is required.`;
    });

    // Handle category validation
    if (formData.category.length === 0) {
      tempErrors.category = "At least one category is required.";
    }

    if (!profileImage) {
      tempErrors.profileImage = "Profile image is required.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });
    if (profileImage) {
      data.append("profileImage", profileImage);
    }

    

    onSubmit(data); // Pass FormData to the parent component

    // Reset the form on successful submission
    // setFormData({
    //   ...initialData,
    //   category: initialData.category ? initialData.category.split(",") : [],
    // });
    // setProfileImage(null);
    // setErrors({});
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!validate()) return;

  //   const data = new FormData();
  //   Object.keys(formData).forEach((key) => {
  //     data.append(key, formData[key]);
  //   });
  //   if (profileImage) {
  //     data.append("profileImage", profileImage);
  //   }

  //   onSubmit(data);
  //   console.log("data:");

  //   console.log(data);

  //   // Reset the form on successful submission
  //   // setFormData({
  //   //   ...initialData,
  //   //   category: initialData.category ? initialData.category.split(",") : [],
  //   // });
  //   // setProfileImage(null);
  //   // setErrors({});
  // };

  return (
    <div className="max-w-4xl mx-auto p-4 sm:p-6">
      <img
        src={FORM_IMG}
        alt="Asian Powerlifting Championship"
        className="w-full mb-4 sm:mb-6 rounded-lg"
      />

      <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
        {/* Personal Information Section */}
        <FormCardWrapper title="Personal Information">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormField
              label="Name"
              name="name"
              type="text"
              value={formData.name || ""}
              onChange={handleChange}
              error={errors.name}
            />
            <FormField
              label="Email"
              name="email"
              type="email"
              value={formData.email || ""}
              onChange={handleChange}
              error={errors.email}
            />
            <FormField
              label="Country"
              name="country"
              type="text"
              value={formData.country || ""}
              onChange={handleChange}
              error={errors.country}
            />
            <FormField
              label="WhatsApp Contact Number"
              name="contactNumber"
              type="text"
              value={formData.contactNumber || ""}
              onChange={handleChange}
              error={errors.contactNumber}
            />
            <FormField
              label="Emergency Contact Name"
              name="emergencyContactName"
              type="text"
              value={formData.emergencyContactName || ""}
              onChange={handleChange}
              error={errors.emergencyContactName}
            />
            <FormField
              label="Emergency Contact Number"
              name="emergencyContactNumber"
              type="text"
              value={formData.emergencyContactNumber || ""}
              onChange={handleChange}
              error={errors.emergencyContactNumber}
            />
          </div>
        </FormCardWrapper>

        {/* Competition Details Section */}
        <FormCardWrapper title="Competition Details">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormField
              label="Passport Number"
              name="passportNumber"
              type="text"
              value={formData.passportNumber || ""}
              onChange={handleChange}
              error={errors.passportNumber}
            />
            <FormField
              label="Date of Birth"
              name="dob"
              type="date"
              value={formData.dob || ""}
              onChange={handleChange}
              error={errors.dob}
            />
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Category
              </label>
              <div className="flex items-center gap-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="category"
                    value="Open"
                    checked={formData.category.includes("Open")}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Open
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    name="category"
                    value="Age group"
                    checked={formData.category.includes("Age group")}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Age group
                </label>
              </div>
              {errors.category && (
                <p className="text-red-500 text-sm">{errors.category}</p>
              )}
            </div>

            {/* Gender Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Gender
              </label>
              <div className="flex items-center gap-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="Men"
                    checked={formData.gender === "Men"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Men
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="gender"
                    value="Women"
                    checked={formData.gender === "Women"}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Women
                </label>
              </div>
              {errors.gender && (
                <p className="text-red-500 text-sm">{errors.gender}</p>
              )}
            </div>

            {/* Weight Category Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Weight Category
              </label>
              <p className="text-xs text-gray-500 mb-2">
                Select the weight category you want to compete in.
              </p>
              <div className="flex flex-wrap items-center gap-4">
                {(weightCategories[formData.gender] || []).map((category) => (
                  <label key={category.value} className="flex items-center">
                    <input
                      type="radio"
                      name="weightCategory"
                      value={category.value}
                      checked={formData.weightCategory === category.value}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    {category.label}
                  </label>
                ))}
              </div>
              {errors.weightCategory && (
                <p className="text-red-500 text-sm">{errors.weightCategory}</p>
              )}
            </div>
          </div>
        </FormCardWrapper>

        {/* Profile Image Section */}
        <FormCardWrapper title="Profile Image">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Profile Image
            </label>
            <input
              type="file"
              name="profileImage"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full border rounded p-2"
            />
            {errors.profileImage && (
              <p className="text-red-500 text-sm">{errors.profileImage}</p>
            )}
          </div>
        </FormCardWrapper>

        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

const FormField = ({ label, name, type, value, onChange, error }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-1">
      {label}
    </label>
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      className="w-full border rounded p-2"
    />
    {error && <p className="text-red-500 text-sm">{error}</p>}
  </div>
);

export default PowerliftingAsianGameForm;
