import { apiSlice } from "./apiSlice";

export const moneyFlowApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMoneyFlowsByGymId: builder.query({
      query: ({ gymId, pageNo = 0, pageSize = 25, sortBy = "transactionDate", sortOrder = "asc" }) => ({
        url: `/api/moneyflows/gym/${gymId}`,
        params: { pageNo, pageSize, sortBy, sortOrder },
      }),
      providesTags: ["MoneyFlows"],
    }),

    getMoneyFlowById: builder.query({
      query: (id) => `/api/moneyflows/${id}`,
      providesTags: ["MoneyFlows"],
    }),

    createMoneyFlow: builder.mutation({
      query: (moneyFlowDto) => ({
        url: `/api/moneyflows`,
        method: "POST",
        body: moneyFlowDto,
      }),
      invalidatesTags: ["MoneyFlows"],
    }),

    updateMoneyFlow: builder.mutation({
      query: ({ id, ...moneyFlowDto }) => ({
        url: `/api/moneyflows/${id}`,
        method: "PUT",
        body: moneyFlowDto,
      }),
      invalidatesTags: ["MoneyFlows"],
    }),

    deleteMoneyFlow: builder.mutation({
      query: (id) => ({
        url: `/api/moneyflows/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["MoneyFlows"],
    }),

    getUniqueTagsByGymId: builder.query({
      query: (gymId) => `/api/moneyflows/gym/${gymId}/tags`,
      providesTags: ["MoneyFlows"],
    }),

    getAnalyticsData: builder.query({
      query: (gymId) => `/api/moneyflows/gym/${gymId}/analytics`,
      providesTags: ["MoneyFlows"],
    }),

    searchMoneyFlows: builder.query({
      query: ({ gymId, transactionType, startDate, endDate, tags, sortBy = "transactionDate", sortOrder = "desc", pageNo = 0, pageSize = 25 }) => {
        const params = { sortBy, sortOrder, pageNo, pageSize };
        if (transactionType) params.transactionType = transactionType;
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        if (tags) params.tags = tags.join(",");

        return {
          url: `/api/moneyflows/gym/${gymId}/search`,
          params,
        };
      },
      providesTags: ["MoneyFlows", "Payments"],
    }),

    getTagDistribution: builder.query({
      query: ({ gymId, startDate, endDate }) => ({
        url: `/api/moneyflows/distribution`,
        params: { gymId, startDate, endDate },
      }),
      providesTags: ["MoneyFlows"],
    }),
    
    getMoneyFlowBasicInfo: builder.query({
      query: (gymId) => ({
        url: `/api/moneyflows/basic-info`,
        params: { gymId },
      }),
      providesTags: ["MoneyFlows"],
    }),


    getAggregatedReport: builder.query({
      query: ({ gymId, transactionType, startDate, endDate, tags, reportType = "MONTHLY" }) => {
        const params = { gymId, reportType };
        if (transactionType) params.transactionType = transactionType;
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        if (tags && tags.length > 0) params.tags = tags;

        return {
          url: `/api/moneyflows/aggregated-report`,
          params,
        };
      },
      providesTags: ["MoneyFlows"],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetMoneyFlowsByGymIdQuery,
  useGetMoneyFlowByIdQuery,
  useCreateMoneyFlowMutation,
  useUpdateMoneyFlowMutation,
  useDeleteMoneyFlowMutation,
  useGetUniqueTagsByGymIdQuery,
  useGetAnalyticsDataQuery,
  useSearchMoneyFlowsQuery,
  useGetMoneyFlowBasicInfoQuery,
  useGetTagDistributionQuery,
  useGetAggregatedReportQuery, // Updated Hook for Aggregated Reports
} = moneyFlowApiSlice;
