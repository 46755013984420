import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import useSnackbar from "../../hooks/useSnackbar";
import { useGetPaymentTypesQuery } from "../../redux/enumApiSlice";
import DynamicDropdown from "../../components/DynamicDropdown";
import FormCardWrapper from "../../components/FormCardWrapper"; // Import the FormCardWrapper

const ClientPaymentForm = ({ initialData, onSubmit, isLoading }) => {
  const [paymentData, setPaymentData] = useState({
    ...initialData,
    billNo: initialData?.billNo || "", // Initialize billNo field
  });
  const [errors, setErrors] = useState({});
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  useEffect(() => {
    setPaymentData((prev) => ({
      ...prev,
      ...initialData,
      billNo: initialData?.billNo || "", // Set billNo when initialData changes
    }));
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelect = (field) => (value) => {
    setPaymentData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!paymentData.amount) tempErrors.amount = "Amount is required.";
    if (!paymentData.paymentDate) tempErrors.paymentDate = "Payment Date is required.";
    if (!paymentData.paymentType) tempErrors.paymentType = "Payment Type is required.";
    if (!paymentData.billNo) tempErrors.billNo = "Bill Number is required."; // Validate billNo
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      await onSubmit(paymentData);
      showSnackbar("Payment added successfully!", "success");
      setPaymentData({
        clientId: paymentData.clientId,
        invoiceId: paymentData.invoiceId,
        amount: "",
        paymentDate: dayjs().format("YYYY-MM-DD"),
        paymentType: "",
        billNo: "", // Reset billNo
      });
    } catch (err) {
      console.error("Failed to add payment:", err);
      showSnackbar("Failed to add payment. " + (err.data?.message || "Unknown error"), "error");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <FormCardWrapper title="Payment Details">
        <form onSubmit={handleSubmit} className="w-full">
          {/* Bill Number Field */}
          <div className="mb-4">
            <label className="block text-gray-700">Bill Number</label>
            <input
              type="text"
              name="billNo"
              value={paymentData.billNo}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
            {errors.billNo && (
              <div className="text-red-500">{errors.billNo}</div>
            )}
          </div>

          {/* Amount Field */}
          <div className="mb-4">
            <label className="block text-gray-700">Amount</label>
            <input
              type="number"
              name="amount"
              value={paymentData.amount}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
            {errors.amount && (
              <div className="text-red-500">{errors.amount}</div>
            )}
          </div>

          {/* Payment Date Field */}
          <div className="mb-4">
            <label className="block text-gray-700">Payment Date</label>
            <input
              type="date"
              name="paymentDate"
              value={paymentData.paymentDate}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              required
            />
            {errors.paymentDate && (
              <div className="text-red-500">{errors.paymentDate}</div>
            )}
          </div>

          {/* Payment Type Field */}
          <div className="mb-4">
            <DynamicDropdown
              label="Payment Type"
              useQueryHook={useGetPaymentTypesQuery}
              onSelect={handleSelect("paymentType")}
              value={paymentData.paymentType}
              queryParams={{}}
            />
            {errors.paymentType && (
              <div className="text-red-500">{errors.paymentType}</div>
            )}
          </div>

          <div className="mb-4 flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded max-w-lg w-full"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Add Payment"}
            </button>
          </div>
        </form>
      </FormCardWrapper>
      <SnackbarComponent />
    </div>
  );
};

export default ClientPaymentForm;
