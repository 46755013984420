import { useState, useEffect } from "react";
import useSnackbar from "../../hooks/useSnackbar";
import FormCardWrapper from "../../components/FormCardWrapper";
import { useGetUniqueTagsByGymIdQuery } from "../../redux/moneyFlowApiSlice";
import { useSelector } from "react-redux";
import { selectGym } from "../../redux/gymSlice";

const MoneyFlowForm = ({ initialData, onSubmit, isLoading }) => {
  const { gymId } = useSelector(selectGym);

  const [moneyFlowData, setMoneyFlowData] = useState({
    ...initialData,
    transactionDate: initialData?.transactionDate || new Date().toISOString().slice(0, 16),
    tags: initialData?.tags || [], // No default "Miscellaneous" tag
  });
  const [errors, setErrors] = useState({});
  const [newTagsInput, setNewTagsInput] = useState("");
  const [dynamicTags, setDynamicTags] = useState([]);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const { data: tagsData } = useGetUniqueTagsByGymIdQuery(gymId);
  const existingTags = tagsData || [];

  useEffect(() => {
    setMoneyFlowData((prev) => ({
      ...prev,
      transactionDate: initialData?.transactionDate || new Date().toISOString().slice(0, 16),
      tags: initialData?.tags || [],
    }));
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMoneyFlowData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleTagToggle = (tag) => {
    setMoneyFlowData((prev) => {
      const updatedTags = prev.tags?.includes(tag)
        ? prev.tags.filter((t) => t !== tag)
        : [...(prev.tags || []), tag];
      return { ...prev, tags: updatedTags };
    });
  };

  const handleNewTagsInput = (e) => {
    setNewTagsInput(e.target.value);
  };

  const handleAddNewTags = () => {
    const newTags = newTagsInput
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag);

    setDynamicTags((prev) => Array.from(new Set([...prev, ...newTags])));
    setMoneyFlowData((prev) => ({
      ...prev,
      tags: Array.from(new Set([...(prev.tags || []), ...newTags])),
    }));
    setNewTagsInput("");
  };

  const validate = () => {
    const tempErrors = {};

    if (!moneyFlowData.transactionDate) tempErrors.transactionDate = "Transaction Date is required.";
    if (!moneyFlowData.transactionType) tempErrors.transactionType = "Transaction Type is required.";
    if (!moneyFlowData.amount || moneyFlowData.amount <= 0) tempErrors.amount = "Amount must be greater than zero.";
    if (!moneyFlowData.tags || moneyFlowData.tags.length === 0) tempErrors.tags = "At least one tag is required."; // Validate tags

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      await onSubmit(moneyFlowData);
      showSnackbar("Money Flow processed successfully!", "success");
    } catch (err) {
      console.error("Failed to process money flow:", err);
      showSnackbar("Failed to process money flow.", "error");
    }
  };

  return (
    <div className="container mx-auto">
      <form onSubmit={handleSubmit}>
        <FormCardWrapper title="Money Flow Details">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700">Bill Number</label>
              <input
                type="text"
                name="billNo"
                value={moneyFlowData.billNo}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Transaction Date</label>
              <input
                type="datetime-local"
                name="transactionDate"
                value={moneyFlowData.transactionDate}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
              {errors.transactionDate && <div className="text-red-500">{errors.transactionDate}</div>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Transaction Type</label>
              <select
                name="transactionType"
                value={moneyFlowData.transactionType}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              >
                <option value="">Select Type</option>
                <option value="INCOME">INCOME</option>
                <option value="EXPENSE">EXPENSE</option>
              </select>
              {errors.transactionType && <div className="text-red-500">{errors.transactionType}</div>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Amount</label>
              <input
                type="number"
                name="amount"
                value={moneyFlowData.amount}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                required
              />
              {errors.amount && <div className="text-red-500">{errors.amount}</div>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Tags</label>
              <div className="flex items-center mb-4">
                <input
                  type="text"
                  value={newTagsInput}
                  onChange={handleNewTagsInput}
                  placeholder="Add tags (comma-separated)"
                  className="w-full p-2 border rounded mr-2"
                />
                <button
                  type="button"
                  onClick={handleAddNewTags}
                  className="bg-green-500 text-white px-4 py-2 rounded"
                >
                  Add Tags
                </button>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                {[...existingTags, ...dynamicTags].map((tag, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      checked={moneyFlowData.tags?.includes(tag) || false}
                      onChange={() => handleTagToggle(tag)}
                      className="form-checkbox"
                    />
                    <span className="text-gray-800">{tag}</span>
                  </div>
                ))}
              </div>
              {errors.tags && <div className="text-red-500">{errors.tags}</div>}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Description</label>
              <textarea
                name="description"
                value={moneyFlowData.description}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>
          </div>

          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Money Flow"}
            </button>
          </div>
        </FormCardWrapper>
      </form>
      <SnackbarComponent />
    </div>
  );
};

export default MoneyFlowForm;
