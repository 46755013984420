import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useSnackbar from "../../hooks/useSnackbar";
import Table from "../../components/Table";
import {
  useSearchMoneyFlowsQuery,
  useGetUniqueTagsByGymIdQuery,
  useDeleteMoneyFlowMutation,
} from "../../redux/moneyFlowApiSlice";
import RouteConfig from "../../config/RouteConfig";
import { selectGym } from "../../redux/gymSlice";
import { useSelector } from "react-redux";

const MoneyFlowScreen = () => {
  const navigate = useNavigate();
  const { SnackbarComponent, showSnackbar } = useSnackbar();
  const { gymId } = useSelector(selectGym);

  const [filters, setFilters] = useState({
    transactionType: [],
    startDate: "",
    endDate: "",
    month: "",
    year: new Date().getFullYear(),
    tags: [],
    pageNo: 0,
    pageSize: 100,
  });

  const { data: tagsData } = useGetUniqueTagsByGymIdQuery(gymId); // Replace gymId with dynamic value
  const [deleteMoneyFlow] = useDeleteMoneyFlowMutation();

  const handleMonthYearChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => {
      const updatedFilters = { ...prev, [name]: value };

      if (updatedFilters.month && updatedFilters.year) {
        const year = updatedFilters.year || new Date().getFullYear();
        const month = updatedFilters.month || 1; // Default to January if no month selected
        updatedFilters.startDate = new Date(year, month - 1, 1)
          .toISOString()
          .split("T")[0];
        updatedFilters.endDate = new Date(year, month, 0)
          .toISOString()
          .split("T")[0];
      }

      return updatedFilters;
    });
  };

  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "transactionType" || name === "tags") {
      setFilters((prev) => ({
        ...prev,
        [name]: checked
          ? [...prev[name], value]
          : prev[name].filter((item) => item !== value),
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlePageChange = (newPageNo) => {
    setFilters((prev) => ({ ...prev, pageNo: newPageNo }));
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    setFilters((prev) => ({ ...prev, pageSize: newSize, pageNo: 0 }));
  };

  const formattedFilters = {
    ...filters,
    startDate: filters.startDate ? `${filters.startDate}T00:00:00` : null,
    endDate: filters.endDate ? `${filters.endDate}T23:59:59` : null,
    transactionType:
      filters.transactionType.length > 0 ? filters.transactionType : null,
    tags: filters.tags.length > 0 ? filters.tags : null,
  };

  const { data, isLoading, isError, error } = useSearchMoneyFlowsQuery({
    gymId: gymId,
    ...formattedFilters,
  });

  const headers = {
    values: [
      "Bill No",
      "Transaction Date",
      "Transaction Type",
      "Tags",
      "Amount",
      "Description",
    ],
  };

  const moneyFlows = data?.content || [];
  const pagination = data?.paginationResponse || {};
  const rows = moneyFlows.map((flow) => ({
    id: flow.id,
    "Bill No": flow.billNo,
    "Transaction Date": new Date(flow.transactionDate).toLocaleString(),
    "Transaction Type": (
      <span
        className={`px-2 py-1 rounded text-white ${
          flow.transactionType === "INCOME" ? "bg-green-500" : "bg-red-500"
        }`}
      >
        {flow.transactionType}
      </span>
    ),
    Tags: flow.tags.join(", "),
    Amount: `Rs ${flow.amount}`,
    Description: flow.description,
  }));

  const handleEdit = (id) => {
    navigate(`${RouteConfig.MONEY}/edit/${id}`);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this Money Flow?")) {
      try {
        await deleteMoneyFlow(id).unwrap();
        showSnackbar("Money Flow deleted successfully!", "success");
      } catch (err) {
        console.error("Failed to delete money flow:", err);
        showSnackbar("Failed to delete money flow.", "error");
      }
    }
  };

  const handleAdd = () => {
    navigate(`${RouteConfig.MONEY}/add`);
  };

  const handleAnalytics = () => {
    navigate(`${RouteConfig.MONEY}/analytics`);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Money Flows</h1>
      <div className="flex  items-center mb-6 ">
        <button onClick={handleAdd} className="btn btn-primary">
          Add Money Flow
        </button>
        <button onClick={handleAnalytics} className="btn btn-secondary ml-3">
          Analytics
        </button>
      </div>

      {/* Filter Section */}
      <div className="mb-6 p-6 border rounded-lg shadow bg-white">
        <h2 className="text-lg font-semibold mb-4">Filters</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Month and Year */}
          <div>
            <label className="block font-medium mb-2">Month</label>
            <select
              name="month"
              value={filters.month}
              onChange={handleMonthYearChange}
              className="form-select w-full"
            >
              <option value="">Select Month</option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block font-medium mb-2">Year</label>
            <input
              type="number"
              name="year"
              value={filters.year}
              onChange={handleMonthYearChange}
              className="form-input w-full"
              min="2000"
              max="2100"
            />
          </div>

          {/* From and To Date */}
          <div>
            <label className="block font-medium mb-2">From Date</label>
            <input
              type="date"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
              className="form-input w-full"
            />
          </div>
          <div>
            <label className="block font-medium mb-2">To Date</label>
            <input
              type="date"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
              className="form-input w-full"
            />
          </div>

          {/* Transaction Type */}
          <div>
            <label className="block font-medium mb-2">Transaction Type</label>
            <div className="flex items-center gap-4">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="transactionType"
                  value="INCOME"
                  checked={filters.transactionType.includes("INCOME")}
                  onChange={handleFilterChange}
                  className="form-checkbox"
                />
                Income
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="transactionType"
                  value="EXPENSE"
                  checked={filters.transactionType.includes("EXPENSE")}
                  onChange={handleFilterChange}
                  className="form-checkbox"
                />
                Expense
              </label>
            </div>
          </div>

          {/* Tags */}
          <div className="md:col-span-2">
            <label className="block font-medium mb-2">Tags</label>
            <div className="flex flex-wrap gap-4">
              {tagsData?.map((tag) => (
                <label key={tag} className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="tags"
                    value={tag}
                    checked={filters.tags.includes(tag)}
                    onChange={handleFilterChange}
                    className="form-checkbox"
                  />
                  {tag}
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Table Section */}
      {isError && (
        <div className="text-red-500">
          Error: {error?.data?.message || "Failed to load money flows"}
        </div>
      )}
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Table
            headers={headers}
            rows={rows}
            ActionsComponent={({ data }) => (
              <div className="flex gap-2">
                <button
                  onClick={() => handleEdit(data.id)}
                  className="btn btn-sm btn-primary"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(data.id)}
                  className="btn btn-sm btn-danger"
                >
                  Delete
                </button>
              </div>
            )}
          />

          {/* Pagination */}
          <div className="mt-4 flex items-center justify-between">
            <div>
              <label className="mr-2">Page Size:</label>
              <select
                value={filters.pageSize}
                onChange={handlePageSizeChange}
                className="form-select"
              >
                {[10, 25, 50, 100].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => handlePageChange(pagination.pageNo - 1)}
                className="btn btn-sm"
                disabled={pagination.pageNo === 0}
              >
                Previous
              </button>
              <span>
                Page {pagination.pageNo + 1} of {pagination.totalPages}
              </span>
              <button
                onClick={() => handlePageChange(pagination.pageNo + 1)}
                className="btn btn-sm"
                disabled={pagination.last}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}

      <SnackbarComponent />
    </div>
  );
};

export default MoneyFlowScreen;
